<!-- 票据提现,授信还款 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20">
			<div class="font-size18 font-color-333 font-weight700 margin-t-15">
				{{title}}
			</div>
			<div class="margin-t-6 recharge-wallet-box position-relative wallet-box">
				<div class="box">
					<div class="card-box flex-row-align-center">
						<div class="user-img-box margin-r-6 flex-row-align-center">
							<imageBox class='user-img' :width='60' :height='60' :borderRidus='50' :src="url"></imageBox>
						</div>
						<div class="flex-colum user-name-box">
							<div class="font-size15 font-weight700">{{ shopName }}</div>
							<div class="font-size14 margin-t-10 flex-row-wrap">
								<div class="margin-r-5">{{moneyName!=''?moneyName:'账户余额'}}:</div>
								<div class="font-color-FF0000 margin-r-10">{{maxAmount|NumFormat}}元</div>
								<div class="color-theme underline pointer" @click="allwithdrawal">全部{{walletType=='SX'?'还款':'提现'}}</div>
							</div>
						</div>
					</div>
					<el-form ref="form" :rules="rules" :model="form" label-position="right" v-if="fIsShow==1">
						<el-form-item label="提现方式：" prop="fRefundMode">
							<el-select v-model="form.fRefundMode" placeholder="请选择提现方式" ref="fRefundMode">
								<el-option style="width: 218px;" v-for="(item,index) in fRefundModeList" :key="index" :label="item.fRefundModeValue"
								 :value="item.fRefundMode"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
					<div v-if="lastTime!=''" class="lastTime font-size14 font-color-999">最后还款日 : {{lastTime}}</div>
					<div class="recharge-amount-box flex-row-align-center border-bottom-F2F2F2 showin input-val">
						<div class="font-size14 font-color-333 recharge-amount-text">{{walletType=='SX'?'还款':'提现'}}金额:</div>
						<div class="font-size30 font-color-333">
							￥
						</div>
						<el-input ref="amount" :autofocus="true" v-model="amount" @input="setOnlyMoney" :maxlength="moneyMaxLeng"></el-input>
					</div>
					<div class="recharge-button font-size14 background-color-theme flex-row-center-center pointer" @click="changePayment">立即{{walletType=='SX'?'还款':'提现'}}</div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import imageBox from '@/components/imageBox/imageBox.vue'
	export default {
		components: {
			imageBox
		},
		data() {
			return {
				fIsShow: 0,
				fRefundModeList: [],
				form: {
					fRefundMode: '',
				},
				title: '', //标题
				walletType: '', //钱包类型 票据钱包:PJ 授信钱包:SX
				amount: '', //金额
				moneyMaxLeng: 13, //金额输入框最大长度
				shopName: '', //店铺名
				shopID: '', //店铺ID
				maxAmount: '', //账户余额
				moneyName: '', //余额或代还款金额
				lastTime: '', //最后还款日
				// url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
				url: require("@/assets/personal.png"),
				rules: {
					fRefundMode: [{
						required: true,
						message: '请选择提现方式',
						trigger: 'change'
					}],
				},
				actualAmount: '', //不带千分符的金额
			};
		},
		mounted() {
			this.$refs.amount.focus();
			// let data = JSON.parse(decodeURIComponent(this.$route.query.data));
			let data = JSON.parse(this.$route.query.data);
			this.title = data.title;
			this.walletType = data.walletType;
			this.shopName = data.shopName;
			this.maxAmount = data.maxAmount;
			this.shopID = data.shopID;
			if (this.walletType == 'SX') {
				this.moneyName = '待还款金额'
				this.lastTime = this.getDate(data.lastTime);
				this.fIsShow = 0;
			} else {
				this.getfRefundModeList();
			}

		},
		computed: {
			...mapGetters(["getThemeName", "getUserInfo"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			//获取下拉框的数据
			getfRefundModeList() {
				this.ApiRequestPostNOMess('/api/mall/ebsale/refund-bill/get-isshow-combobox', {
					"fShopID": this.shopID
				}).then(result => {
					if (result.obj.showComboBox.length > 0) {
						this.fIsShow = result.obj.fIsShow;
						this.fRefundModeList = result.obj.showComboBox
					}
				}, rej => {})
			},
			//全部提现 还款
			allwithdrawal() {
				let val = this.maxAmount.toString()
				this.actualAmount = this.maxAmount.toString();
				let num = val.split("."); // 分隔小数点
				let arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
				let res = [];
				for (let i = 0, len = arr.length; i < len; i++) {
					if (i % 3 === 0 && i !== 0) {
						res.push(","); // 添加分隔符
					}
					res.push(arr[i]);
				}
				res.reverse(); // 再次倒序成为正确的顺序
				if (num[1]) { // 如果有小数的话添加小数部分
					if (num[1].length == 1) {
						res = res.join("").concat("." + num[1] + '0');
					} else {
						res = res.join("").concat("." + num[1]);
					}
				} else {
					res = res.join("").concat("." + "00");
				}
				this.amount = res;
			},
			//输入金额限制
			setOnlyMoney() {
				this.$nextTick(() => {
					let val = this.amount.toString();
					val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
					val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
					val = val.replace(/^0+\./g, '0.');
					val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
					val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || ''
					this.actualAmount = val
					let res = [];
					if (val.includes(".")) {
						let num = val.split("."); // 分隔小数点
						let arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
						for (let i = 0, len = arr.length; i < len; i++) {
							if (i % 3 === 0 && i !== 0) {
								res.push(","); // 添加分隔符
							}
							res.push(arr[i]);
						}
						res.reverse(); // 再次倒序成为正确的顺序
						if (num[1]) { // 如果有小数的话添加小数部分
							res = res.join("").concat("." + num[1]);
						} else {
							res = res.join("").concat(".");
						}
					} else {
						let num = val.split("."); // 分隔小数点
						let arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
						for (let i = 0, len = arr.length; i < len; i++) {
							if (i % 3 === 0 && i !== 0) {
								res.push(","); // 添加分隔符
							}
							res.push(arr[i]);
						}
						res.reverse(); // 再次倒序成为正确的顺序
						res = res.join("");
					}
					this.amount = res;
				});
			},
			//提现还款
			changePayment() {

				if (!this.actualAmount) {
					this.$message('请输入金额');
					return
				}
				if (this.actualAmount == 0) {
					if (this.walletType == 'PJ') {
						this.$message('提现金额不能为0');
					} else {
						this.$message('还款金额不能为0');
					}
					return
				}
				if (this.walletType == 'PJ') { //票据钱包
					if (this.actualAmount > this.maxAmount) {
						this.$message('您所提现的金额超出当前账户余额，请重新输入');
					} else if (this.actualAmount > 99999999.99) {
						this.$message('最大提现金额为99999999.99，请重新输入');
					} else {
						if (this.fIsShow == 1) {
							this.$refs.form.validate((valid) => {
								if (valid) {
									this.rechargeTicket();
								} else {
									return false
								}
							})
						} else {
							this.rechargeTicket();
						}

					}
				} else if (this.walletType == 'SX') { //授信额度		
					if (this.actualAmount > this.maxAmount) {
						this.$message('您所还款的金额超出当前待还款金额，请重新输入');
					} else if (this.actualAmount > 99999999.99) {
						this.$message('最大还款金额为99999999.99，请重新输入');
					} else {
						this.rechargeCredit();
					}
				}
			},
			//票据提现
			rechargeTicket() {
				this.ApiRequestPostNOMess('/api/mall/ebsale/refund-bill/create-bill-return', {
						fMoney: this.actualAmount,
						fAppTypeID: this.fAppTypeID,
						fShopUnitID: this.shopID,
						fRefundMode: this.form.fRefundMode == '' ? 0 : this.form.fRefundMode
					})
					.then(res => {
						console.log("票据提现___完成", res);
						// Auditing字段 1表示审核中 0表示初始化 254 表示审核通过
						if (res.obj.status) {
							let obj = {
								successType: 'TX',
								examine: res.obj.Auditing,
								billNumber: res.obj.fRefundBillNumber,
								amount: this.actualAmount,
								type: 'PJ',
								fBillTypeID: '200000837',
								fSettleModeID:res.obj.fSettleModeID,
								reID: res.obj.fRefundBillID,
							}
							this.$router.replace({
								path: '/businessme/RechSuccess',
								query: {
									// data: encodeURIComponent(JSON.stringify(obj))
									data: JSON.stringify(obj)
								}
							})
						} else {
							this.$message({
								message: res.obj.message,
								type: 'warning'
							});
						}
					}, error => {});
			},
			//授信还款
			rechargeCredit() {
				console.log("授信还款");
				this.$router.replace({
					name: "PaymentOrder",
					params: {
						fBillTypeID: '200000905',
						money: this.actualAmount,
						title: this.title,
						shopID: this.shopID
					}
				})
			},
		}

	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.card-box {
		// width: 350px;
		height: 90px;
	}

	.user-name-box {
		color: #1F4E9E;
	}

	.user-img-box {
		width: 80px;
		height: 80px;
	}

	.user-img {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	}

	.recharge-wallet-box {
		// padding: 14px 0 0 20px;
		width: 897px;
		height: 367px;
		background: url(../../../assets/imgs/pay/web02.jpg) no-repeat;
		// background-size: 100% 378px;
	}

	.wallet-box {
		height: 400px;
		box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
		box-sizing: border-box;
		padding-top: 30px;
		margin-top: 10px;
		border-radius: 5px;
	}

	.box {
		min-height: 260px;
		width: 300px;
		margin-left: 60px;
		padding: 20px;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
		background-color: #FFFFFF;
	}

	.underline {
		text-decoration: underline;
	}

	.back-card-logo-img {
		width: 50px;
		height: 20px;
		background-color: #CCCCCC;
	}

	.changeBankCard:hover {
		cursor: pointer;
	}

	.recharge-amount-box {
		margin-top: 20px;
		height: 44px;
		width: 300px;
		padding-bottom: 10px;
	}

	.recharge-amount-text {
		width: 70px;
	}

	.recharge-button {
		margin: 40px 0 0 0px;
		width: 300px;
		height: 45px;
		border-radius: 0px;
	}

	.lastTime {
		margin-left: 85px;
	}

	/deep/.el-input {
		width: 218px;
	}


	.showop .el-input__inner {
		font-size: 18px !important;
	}

	/deep/.showin .el-input__inner {
		// width: 300px;
		height: 44px !important;
		font-size: 24px;
		padding: 0;
		color: #000000;
		border: none !important;
		background-color: rgba(255, 255, 255, 0);
	}

	/deep/.el-form-item__label {
		padding: 0;
	}

	/deep/.input-val .el-input {
		width: 180px;
	}
</style>
